<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    wrapClassName="deactivate-account"
  >
    <p class="message">
      {{ message }}
    </p>
    <div class="all-btns">
      <a-button
        data-cy="conformGroupDeleteButton"
        class="btn"
        :class="[invertButtonColors ? 'color-primary': 'deactivate-btn']"
        :loading="loading"
        @click="takeAction()"
        >{{ confirmText }}</a-button
      >
      <button data-cy="doNotDeleteButton" class="btn" 
      :class="[invertButtonColors ? 'color-gray' : 'delet-btn']" 
      @click="closeModal()">
        {{ cancelText }}
      </button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    "visible":Boolean, 
    "confirmText": String, 
    "cancelText": String, 
    "message": String, 
    "loading":Boolean,
    "invertButtonColors":{
      type:Boolean,
      default: () => (false)
    }
  },

  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    takeAction() {
      this.$emit("delete-record", true);
    },
  },
};
</script>


<style lang="scss">
.deactivate-account {
  .ant-modal {
    width: 50rem !important;
  }
  .ant-modal-header {
    padding: 0;
    border: none;
    margin-bottom: 1rem;
  }
  .ant-modal-content {
    padding: 3rem;
    border-radius: 0;
    position: relative;
    .ant-modal-close {
      margin: 2.7rem 2.5rem 0 0;
    }
  }
  .ant-modal-title {
    font-size: 2rem !important;
    font-family: $font-secondary-bold;
    width: 100%;
    line-height: 2.8rem;
    margin-bottom: 2.5rem;
    padding-right: 3rem;
  }
  .ant-modal-body {
    .message {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      font-family: $font-secondary;
      opacity: 0.5;
      margin-top: -0.5rem;
    }
    .all-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .anticon-loading {
        .anticon-spin {
          color: #be0000 !important;
        }
      }
      .btn {
        border: none;
        font-size: 1.6rem;
        font-family: $font-primary-medium;
        width: 20.5rem;
        height: 4rem;
        cursor: pointer;
        .anticon .anticon-spin {
          font-size: 2rem !important;
        }
      }
      .deactivate-btn {
        background-color: #be0000;
        color: $color-white;
        .anticon .anticon-spin {
          color: #be0000 !important;
        }
      }
      .delet-btn {
        background-color: #d3dcfb;
        color: $color-black;
        .anticon .anticon-spin {
          color: $color-black !important;
        }
      }
      .color-primary {
        background-color: $color-primary;
        color: $color-white;
        .anticon .anticon-spin {
          color: $color-white !important;
        }
      }
      .color-gray {
        background-color: #e7e7e7;
        color: $color-black;
      }
    }
  }
}
</style>
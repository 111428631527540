<template>
  <div class="user-profile-account-settings">
    <a href="/">Back to Glocal</a>
    <a-row :gutter="[18, 8]">
      <a-col :xs="24" :sm="9" :md="8" :lg="8">
        <setting-selection
          @update-component="updateComponent"
          @show-deactivate-modal="showDeleteModal=true"
        ></setting-selection>
      </a-col>
      <a-col :xs="24" :sm="15" :md="16" :lg="16">
        <component :is="activeScreen"></component>
      </a-col>
    </a-row>
    <delete-modal
      :visible="showDeleteModal"
      @handle-close="showDeleteModal=false"
      title="Are you sure you want to deactivate your account?"
      confirmText="Yes, Deactivate"
      cancelText="No, Don't Deactivate"
      message="You will be able to activate your account by login again."
      :loading="onDteReq"
      @delete-record="deactiveAccount"
    ></delete-modal>
  </div>
</template>

<script>
import SettingSelection from "./AccountSetting.vue";
import PersonalInformation from "./PersonalInformation.vue";
import PrivacyProtection from "./PrivacyProtection.vue";
import PaymentMethods from "./PaymentMethods.vue";
import NotificationControl from "./NotificationControl.vue";
import Subscriptions from "./Subscriptions.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";

export default {
  components: {
    SettingSelection,
    PersonalInformation,
    PrivacyProtection,
    PaymentMethods,
    NotificationControl,
    Subscriptions,
    DeleteModal,
  },
  data() {
    return {
      activeScreen: null,
      showDeleteModal: false,
      onDteReq: false
    };
  },
  mounted() {
    this.activeScreen = PersonalInformation;
  },
  methods: {
    updateComponent(selected) {
      this.activeScreen =
        selected == "privacy"
          ? PrivacyProtection
          : selected == "payment"
          ? PaymentMethods
          : selected == "notifications"
          ? NotificationControl
          : selected == "subscription"
          ? Subscriptions
          : PersonalInformation;
    },
    async deactiveAccount(){
      this.onDteReq = true;
      try{
        await this.$store.dispatch('auth/accountDeactivate')
        .then(()=>{
          this.$router.replace('/');
        })
      }
      catch(err){
        console.log(err.response.data);
      }
      this.onDteReq = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-profile-account-settings {
  padding: 12rem 22rem 5rem;
  background-color: $color-light-grey-2;
  height: 100%;
  min-height: 100vh;
  @include respond(tab-land) {
    padding: 12rem 18rem 5rem;
  }
  @include respond(laptop-small) {
    padding: 11rem 6rem 5rem;
  }
  @include respond(tab-port) {
    padding: 11rem 4rem 5rem;
  }
  @include respond(phone-x-small) {
    padding: 14rem 3rem 5rem;
  } 
  a {
    font-size: 1.6rem;
    font-family: $font-primary-bold;
    color: $color-black;
    @include respond(phone-x-small) {
      font-size: 2rem;
    }
  }
  ::v-deep(.update-profile-btn),
  ::v-deep(.request-code-btn),
  ::v-deep(.update-plan-btn) {
    height: 5rem;
    font-size: 1.6rem;
    font-family: $font-primary-medium;
    width: 20rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding: 0 !important;
    @include respond(phone-x-small) {
      font-size: 1.8rem;
      height: 5.5rem;
    }
    span {
      line-height: normal;
    }
    .anticon {
      .anticon-spin {
        font-size: 2.2rem !important;
        color: $color-primary !important;
      }
    }
  }
}
</style>


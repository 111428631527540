<template>
  <div class="subscription-account-settings">
    <div class="subscription-account-settings__header">
      <h4>Glocal Subscription</h4>
      <!-- <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt
      </p> -->
    </div>
    <div v-if="subscribePackage" class="subscription-account-settings__body">
      <h2 class="subscription-account-settings__body--title">Current Plan</h2>
      <div class="subscription-account-settings__body--container">
        <div class="right">
          <div class="right__logo">
            <img src="../../../assets/images/Amblem2.webp" alt="" />
            <div class="title">
              <h1>{{ subscribePackage.plan.description }}</h1>
              <p>
                ${{ subscribePackage.plan.actual_price }}/{{
                  subscribePackage.plan.description
                }}<span>Renews: {{ subscribePackage.end_date }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="left">
          <a-button @click="handleCancelSubscriptionModal">Cancel</a-button>
        </div> -->
      </div>
    </div>
  </div>
  <delete-modal
    :visible="showCancelSubscriptionModal"
    @handle-close="showCancelSubscriptionModal = false"
    title="Are you sure you want to cancel subscription?"
    confirmText="Yes, Cancel"
    cancelText="No, Don't Cancel"
    :loading="onCancelSubscription"
    @delete-record="cancelSubscription"
  ></delete-modal>
</template>

<script>
import Cookies from "js-cookie";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";
export default {
  components: {
    DeleteModal,
  },
  computed: {
    subscribePackage() {
      return this.$store.getters["profile/userInformation"].active_subscription;
    },
  },
  data() {
    return {
      showCancelSubscriptionModal: false,
      onCancelSubscription: false,
    };
  },
  methods: {
    async getCurrentSubscription() {
      try {
        const response = await this.$store.dispatch("auth/getPackages");
        if (response.status == 200) {
          this.subscribePackage = response.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    handleCancelSubscriptionModal() {
      this.showCancelSubscriptionModal = true;
    },
    async cancelSubscription() {
      try {
        this.onCancelSubscription = true;
        const response = await this.$store.dispatch(
          "auth/cancelGlocalSubscription"
        );
        if (response.status == 200) {
          this.$store.commit("profile/setPaidField", false);
          this.$router.push("/subscription");
        }
      } catch (err) {
        console.log(err);
      }
      this.onCancelSubscription = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-account-settings {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 2rem 4rem;
    @include respond(phone-x-small) {
      padding: 2rem;
    }
    h4 {
      font-size: 1.8rem;
      font-family: $font-primary-bold;
      margin-bottom: 0;
      line-height: normal;
      @include respond(phone-x-small) {
        font-size: 2.2rem;
      }
    }
    p {
      font-size: 1.6rem;
      font-family: $font-primary;
      padding: 0.5rem 0;
    }
  }
  &__body {
    padding: 3rem 4rem;
    min-height: 40rem;
    @include respond(phone-x-small) {
      padding: 3rem 2rem;
    }
    &--title {
      font-size: 1.6rem;
      font-family: $font-primary-bold;
      line-height: normal;
      margin-bottom: 0;
      @include respond(phone-x-small) {
        font-size: 1.8rem;
      }
    }
    &--container {
      margin-top: 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 1.5rem;
      border: 1px solid #7070705d;
      border-radius: 0.5rem;
      .right {
        &__logo {
          display: flex;
          align-items: center;
          img {
            width: 5rem;
            height: 5rem;
            object-fit: contain;
          }
          .title {
            h1 {
              font-size: 2.2rem;
              font-family: $font-primary-bold;
              line-height: normal;
              margin: 0.5rem 0;
              text-transform: uppercase;
            }
            p {
              font-size: 1.4rem;
              font-family: $font-primary;
              line-height: normal;
              margin-bottom: 0;
              opacity: 0.6;
              margin-top: 0;
              span {
                margin-left: 1rem;
                padding-left: 1.3rem;
                position: relative;
                &::before {
                  position: absolute;
                  content: "";
                  width: 0.7rem;
                  border-radius: 100%;
                  height: 0.7rem;
                  background-color: $color-black;
                  opacity: 0.4;
                  left: 0;
                  transform: translateY(60%);
                }
              }
            }
          }
        }
      }
      .left {
        .ant-btn {
          background-color: $color-primary;
          color: $color-white;
          display: flex;
          align-items: center;
          height: 4rem;
          width: 11rem;
          justify-content: center;
          font-family: $font-primary-bold;
          font-size: 1.6rem;
          border: none;
        }
      }
    }
  }
}
</style>
